<template>
  <div class="manage-dropdown shortcut_menu_dropdown">
    <b-dropdown offset="25, 0"
                right
                ref="dropdown_select"
                class="dropdown-menu-left hide_caret default_style_dropdown"
              :no-caret="true">
    <div class="dropdown_header border h-8 px-2.5 py-2 rounded-lg d-flex align-items-center " slot="button-content">
      <span class="text">{{ label }}</span>
      <span class="arrow_icon ml-auto"><i class="text-[#757A8A] fal fa-angle-down ml-3"></i></span>
    </div>
      <ul class="w-full text-[#202324] font-poppins py-1 text-[0.875rem] shadow-lg bg-white rounded-tl-lg font-poppins rounded-bl-lg rounded-br-lg">
      <li class="cursor-pointer border-b group hover:rounded-tl-lg px-[1rem] py-3.5 flex justify-between font-poppins items-center" v-if="list && list.length > 0">
        <Checkbox
          label="All Tags"
          id="tag_check_all"
          v-model="selectAll"
          :inputValue="selectAll"
          @change="$emit('change', selectAll)"
          labelClass="font-semibold text-[#3C4549]"
          customClass="w-full justify-between flex-row-reverse"
        ></Checkbox>
      </li>

      <template v-if="list && list.length > 0">
        <template v-for="tag in list">

          <li class="cursor-pointer group hover:rounded-tl-lg hover:bg-[#F4F6FA] px-[1rem] py-3.5 flex justify-between font-poppins items-center">
            <label class="mb-0" :for="tag._id">
              <div class="user_block d-flex align-items-center">
                <div class="user_content">
                  <p class="name">{{ limitTextLength(tag.tag, 21) }}</p>
                </div>
              </div>
            </label>
            <input type="checkbox" v-model="localSelectedList" @input="$emit('input'), $refs.dropdown_select.hide(true)" :id="tag._id" :value="tag._id">
           </li>

        </template>
      </template>
      <li class="no_text_li" v-else>
        {{ notFound }}
      </li>

    </ul>
  </b-dropdown>
  </div>
</template>
<script>

export default {
  name: 'CheckboxFilter',
  data() {
    return {
      selectAll: false,
      localSelectedList: []
    }
  },
  components: {
    Checkbox: () => import('@/ui/ui-kit/v2/Checkbox.vue')
  },
  props: [
    'label',
    'list',
    'selectedList',
    'notFound',
    'isScroll',
    'name'
  ],
  watch: {
    'selectedList' () {
      this.localSelectedList = this.selectedList
    }
  }
}
</script>
